import React, { useState, useRef } from 'react';
import Header from './components/Header/Header';
import FormSection from './components/Form/FormSection';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import ReCAPTCHA from "react-google-recaptcha";
import Web3 from 'web3';


function App() {
    const [captchaValue, setCaptchaValue] = useState<string | null>(null);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [accountBalance, setaccountBalance] = useState<string>('0');


    const recaptchaRef = useRef(null);
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || "";

    const onReCAPTCHAVerified = (value: string | null) => {
        setCaptchaValue(value);
    };

    const getBalance = async (address: string) => {
        try {

            const providerUrl = 'https://trpc.frequencychain.org';
            const web3 = new Web3(new Web3.providers.HttpProvider(providerUrl));
            const balanceWei = await web3.eth.getBalance(address);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            setaccountBalance(parseFloat(balanceEther).toFixed(2));
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };

    return (
        <div>
            <Header
                setWalletAddress={setWalletAddress}
                setaccountBalance={setaccountBalance}
                walletAddress={walletAddress}
                getBalance={getBalance}
            />
            <FormSection
                captchaValue={captchaValue}
                setCaptchaValue={setCaptchaValue}
                recaptchaRef={recaptchaRef}
                walletAddress={walletAddress}
                accountBalance={accountBalance}
                getBalance={getBalance}
                setaccountBalance={setaccountBalance}


            />
            <FAQ />
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptchaKey}
                onChange={onReCAPTCHAVerified}
            />
            <Footer />
        </div>
    );
}

export default App;
